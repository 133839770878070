import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import SplitText from '../lib/SplitText.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(SplitText, ScrollTrigger, ScrollToPlugin);

import Swiper from '../lib/swiper-bundle.min';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
	navlistAnime();
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	nav_current();
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}

function navlistAnime (){
	let menuli = gsap.utils.toArray('#main_nav li');
	menuli.forEach((el, i) => {
		gsap.from(el, {y:-60,opacity:0, duration:0.3, delay:i*0.02+0.3})
	});
}

var nav = $("#nav");
var $navLi = nav.find("li");
function nav_current(){
	$navLi.removeClass("on");
}

function imageSlide(){
	let swiper = new Swiper('#gal_swiper', {
		slidesPerView: 'auto',
		speed: 600,
		spaceBetween: 10,
		simulateTouch: false,
		loop: true,
    slideToClickedSlide: true,
		keyboard: {
			onlyInViewport: false,
		},
		navigation: {
			nextEl: '#galnext',
			prevEl: '#galprev',
		},
  	on: {
			init: function () {
			},
		}
	});
	let m_swiper = new Swiper('#modalgal_swiper', {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		slidesPerView: 1,
		speed: 600,
		spaceBetween: 10,
		loop: true,
		keyboard: {
			onlyInViewport: false,
		},
		navigation: {
			nextEl: '#m_galnext',
			prevEl: '#m_galprev',
		},
	});
	const modal_swiper = document.getElementById('modal_swiper');
	$('#gal_swiper .swiper-slide').on('click',function(){
		const num = $(this).attr('data-num');
		m_swiper.slideToLoop(num);
		modal_swiper.classList.add('active');
	})
	$('#modalgal_close').on('click',function(){
		modal_swiper.classList.remove('active');
	})
}

function singleSlider (){
	$('.single_swiper').each(function(){
		const swiper = new Swiper(this, {
			slidesPerView: 1,
			speed: 600,
			spaceBetween: 10,
			loop: true,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			pagination: {
				el: '.swiper-pagination',
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	});
}


const setVH_Height = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', vh+'px');
}
const setVH = () => {
	const navvh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--kvvh', navvh+'px');
	const vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vw', vw+'px');
}
setVH_Height();
setVH();
window.addEventListener('resize', function () {
	if(window.matchMedia('(min-width:768px)').matches){
		setVH_Height();
	}
	setVH();
});


let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(target.classList.contains('nosp')){
				return;
			}
		}
		if(!target.classList.contains('nomove')){
			target.style.opacity = 0;
		}
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});
	});
}

function elAnime(target){
	target.style.opacity = 1;
	if(target.classList.contains('elp')){
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _y = 120;
		let _opa = 0;
		if(elc.classList.contains('nomove')){
			_y = 0;
			_opa = 1;
		}
		gsap.fromTo(elc,{y:_y,opacity:_opa},{
			y: 0,
			opacity:1,
			duration: 0.8,
			delay: i * 0.2,
			ease:'power1',
			onComplete: function(){
				const _txef = elc.querySelectorAll('.txef');
				if(_txef){
					txtEffect(_txef);
				}
				elc.classList.add('elon');
			}
		});
	});
}

function txtEffectInit (txef){
	gsap.utils.toArray(txef).forEach((el,i) => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(el.classList.contains('nosp')){
				return;
			}
		}
		let mySplitText = new SplitText(el, { type: "chars" });
		let chars = mySplitText.chars;
		chars.forEach((char, i) => {
			gsap.set(char, {opacity:0});
		});
	});
}

function txtEffect (target){
	target.forEach((tar) => {
		let chars = tar.querySelectorAll('div');
		chars.forEach((char, i) => {
			gsap.fromTo(char, 
				{y:'2em',opacity:0},{
				y: 0,
				opacity:1,
				duration: 0.8,
				delay: i * 0.04,
			});
		});
});
}

function parallaxImage() {
	gsap.utils.toArray('.paraimg .img').forEach((paraimg, i) => {
		const heightDiff = paraimg.offsetHeight - paraimg.parentElement.offsetHeight;
		gsap.fromTo(paraimg,{y:-heightDiff}, {
			scrollTrigger: {
				trigger: paraimg.parentElement,
				scrub: true
			},
			y: 0,
			ease: "none"
		});
	});
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 0;
	}else{
		tar_offset = 0;
	}
	$('a.anchor').on('click', function(){
		nav_hide();
		let href = this.hash;
		gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:true, offsetY:tar_offset}, ease:'power2'});

		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			return false;
		}
	});
	$('#scrldown').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:'.scrltarget', autoKill:true, offsetY:tar_offset}, ease:'power2'});
		return false;
	});
	$('#btt').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:0, autoKill:true}, ease:'power2'});
		return false;
	});
}


function expandFunc() {
	let exbtn = document.querySelectorAll('.exbtn');
	for (let i = 0; i < exbtn.length; i++) {
		exbtn[i].addEventListener('click',function(){
			let parent = this.parentElement;
			const result = parent.classList.contains('open');
			if (result) {
				parent.classList.remove('open');
				const exc = parent.querySelectorAll('.exc');
				gsap.utils.toArray(exc).forEach(ex => {
					gsap.to(ex,{
						height: 0,
						duration:0.3,
						ease: "power2.inOut"
					});
				});
			}else{
				parent.classList.add('open');
				const exc = parent.querySelectorAll('.exc');
				gsap.utils.toArray(exc).forEach(ex => {
					gsap.to(ex,{
						height:'auto',
						duration:0.3,
						ease: "power2.inOut",
					});
				});
			}
		})
	}
}

function videoSwitch() {
	var videoelm = document.getElementById('video');
	if(!videoelm) {
		return false;
	}
	var videosp = '_sp.';
	var videopc = '_pc.';
	const src = videoelm.getAttribute('src');
	if(window.matchMedia('(max-width:767px)').matches){
		if(src.includes(videopc)){
			var srcname = videoelm.getAttribute('src').replace(videopc, videosp);
			videoelm.src = srcname;
		}
	}else{
		if(src.includes(videosp)){
			var srcname = videoelm.getAttribute('src').replace(videosp, videopc);
			videoelm.src = srcname;
		}
	}
	videoelm.style.opacity = 1;
}


function mapmodalFunc() {
	$('.modalbtn').on('click', function(){
		const num = $(this).attr('data-num');
		$('.mapmodal').removeClass('active');
		$('#mapmodal'+num).addClass('active');
		$('html').addClass('modalActive');
	});
	$('.mapmodalclose').on('click', function(){
		$('.mapmodal').removeClass('active');
		$('html').removeClass('modalActive');
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		pageScroll();
		elFunc('.el');
		txtEffectInit('.txef');
		parallaxImage();
		expandFunc();
		singleSlider();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: function(){
		body.addClass('top');
		videoSwitch();
		imageSlide();
		mapmodalFunc();
		window.addEventListener('resize', function () {
			videoSwitch();
		});		
	},
	singleFunc: function(){
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		const $newPageBogo = $(data.next.html).find('#bogo').html();
		body.find('#bogo').html($newPageBogo);
	}

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:target, ease:'power2', onComplete:function(){
						resolve();
					}});
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	var $footerelm = $('footer')
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage topready navopen navwh');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}